<template>
  <div class="pd20x flex1">
    <AddShopgoods :visible="openClasses" @close="openClasses = false" :options="options" :data="currentItem" :edit="editClass" @update="getData"></AddShopgoods>
    <div class="contentBox h100pct ofA">
      <div class="filter" style="padding:10px 20px">
        <div>商品数量（{{ pagination.total }}个） </div>
        <div>
          <label>搜索：</label>
          <a-input-search v-model="filter.keywords" placeholder="搜索商品标题" style="width: 200px" @search="()=>{pagination.page = 1;getData()}" class="mr10px"/>
          <a-button type="primary" @click="openClasses = true; currentItem = null; editClass = false">添加商品</a-button>
        </div>
      </div>
      <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :rowKey="'id'"
          @change="changePage"
      >
        <template slot="index" slot-scope="text, record">
          {{record.id}}
        </template>
        <template slot="typeList" slot-scope="text, record">
          <span v-if="record.type == 1">虚拟产品</span>
          <span v-if="record.type == 2">实物产品</span>
        </template>
        <template slot="is_show" slot-scope="text, record">
          <span v-if="record.is_show == 1">开放</span>
          <span v-if="record.is_show == 0">隐藏</span>
        </template>
        <template slot="create_time" slot-scope="text">
          {{formatDate(text*1000)}}
        </template>
        <template slot="operation" slot-scope="text, record">
          <i class="z-icon editB" @click="openClasses = true; currentItem = record; editClass = true"></i>
          <i class="z-icon delete" @click="deleteItem(record.id)"></i>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import {get_shop_goods, del_shop_goods} from "@/libs/examapi";
import {formatDate} from '@/libs/moment'

const columns = [{
  title: '序号',
  scopedSlots: { customRender: 'index' },
  align:'center'
}, {
    title: '商品名称',
    dataIndex: 'title',
    align:'center'
  }, {
    title: '原价',
    dataIndex: 'old_price',
    align:'center'
  }, {
    title: '现价',
    dataIndex: 'now_price',
    align:'center'
  }, {
    title: '类型',
    dataIndex: 'type',
    align:'center',
    scopedSlots: { customRender: 'typeList' }
  }, {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },  {
    title: '状态',
    dataIndex: 'is_show',
    align:'center',
    scopedSlots: { customRender: 'is_show' }
  }, {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
import AddShopgoods from '@/components/exam/addShopgoods'
import * as http from '@/libs/examapi'
import { routerlink } from '@/mixins/routerlink'
export default {
  name: 'goodsList',
  components: { AddShopgoods },
  mixins: [routerlink],
  data(){
    return{
      options: [],
      columns,
      filter:{
        keywords:''
      },
      pagination:{
        page: 1,
        size: '15',
        pageSize: 15
      },
      data: [],
      areaval: '',
      openClasses: false,
      currentItem:'',
      editClass:false
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.getData()
    })
  },
  methods:{
    formatDate,
    getData(){
      let request = {...this.filter,...this.pagination}
      http.get_shop_goods(request).then(res=>{
        const pagination = { ...this.pagination }
        pagination.total = res.total;
        this.data = res.data
        this.pagination = pagination;

      }).catch(error=>{
        console.log(error)
      })
    },
    deleteItem(id){
      let _this = this
      this.$confirm({
        title: '是否确定删除该商品信息?',
        centered: true,
        onOk() {
          http.del_shop_goods({id}).then(res=>{
            let {page, pagesize, total} = _this.pagination
            if(page>1 && Number(page-1)* 15 == total - 1){
              _this.pagination.page = Number(page-1)
            }
            _this.getData()
          }).catch(error=>{
            console.log(error)
          })
        },
      });
    },
    changePage({current}){
      this.pagination.page = current
      this.getData()
    },
    hideModal(){

    },
    onChange(){

    },
  }
}
</script>
<style lang="less" scoped>
.imgWrap{
  width: 120px;
  height: 70px;
  overflow: hidden;
  border-radius: 4px;
  img{
    width: 100%;
  }
}
</style>